import type { FC } from "react"
import { useAppSelector } from "../../hooks/redux"
import { LOCATION_DEFAULT } from "../../utils/constants"
import { AVAILABLE_VIEW_DATA } from "./constants"
import { Layout } from "./Layout"
import { StyledAvailable, StyledAvailableInner } from "./StyledAvailable"
import { AvailablePropsType } from "./types"

const Available: FC<AvailablePropsType> = ({
  status,
  visibleIndicator = false,
}) => {
  const location = useAppSelector(({ app }) => app.location)

  if (!status) {
    return null
  }

  const { icon, color, label } = AVAILABLE_VIEW_DATA[status]

  const isVisible = location?.city === LOCATION_DEFAULT.city || visibleIndicator

  return (
    <StyledAvailable>
      <StyledAvailableInner>
        <Layout
          title={label}
          iconColor={color}
          iconName={icon}
          visibleIndicator={isVisible}
        />
      </StyledAvailableInner>
    </StyledAvailable>
  )
}

export default Available
